import { FC } from "react";
import { Images } from "../../../utils/image";
import "./register.css";
import commerceRegister from "../../../../assets/commerce-register.png"
import { GetStartedButton } from "../../../interactions/button";
import star from "../../../../assets/line_star.png";

export const Register: FC = () =>{
    return(
        <>
            <div className="register_div">
                <div className="register_content">
                    <div className="register_images">
                        <Images source={star} alt = "star line" className="line_star" />
                        <Images source= {commerceRegister} alt = "commerce register" className="commerce_image_register"/>
                    </div>
                    <div className="register_t">
                        <h1 className="register_h1">
                            Register with <span className="register_span" >Sabi</span> Business
                        </h1>
                        <p className="register_p">
                            Create an account and provide compliance information to get your business activated on SabiPay 
                        </p>
                        <div className="register_btn">
                            <GetStartedButton/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}