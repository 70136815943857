import {FC} from "react";
import { AboutUs } from "../../components/layouts/aboutPage/aboutUs";
import { OurExcellentTeam } from "../../components/layouts/aboutPage/ourExcellentTeam";

export const AboutPageView : FC = () =>{
    return(
        <>
            <AboutUs/>
            <OurExcellentTeam/>
        </>
    )
}