import {FC} from "react";
import { ForUsers } from "../../components/layouts/forUsers";
import { GoVisual } from "../../components/layouts/forUsers/goVisual";
import { PaymentRecievedForUsers } from "../../components/layouts/forUsers/paymentRecieved";
import { SenMoneyWithSabiPay } from "../../components/layouts/forUsers/sendmoney/indx";

export const ForUsersView:FC = () => {
    return(
        <>
            <ForUsers/>
            <SenMoneyWithSabiPay/>
            <PaymentRecievedForUsers/>
            <GoVisual/>
        </>
    )
}