import { FC } from "react";
import { Images } from "../../../utils/image";
import fraud from "../../../../assets/fraud.png";
import "./prevention.css";


export const FraudPrevention: FC = () =>{
    return(
        <>
            <div className="fraud_div">
                <div className="fraud_content">
                    <div className="fraud_t">
                        <h2 className="fraud_h2" >Fraud Prevention</h2>
                        <p className="fraud_p">
                            SabiPay's combination of automated and manual fraud systems protect
                            you from fraudulent transactions and associated chargeback claims.  
                        </p>
                    </div>
                    <div className="fraud_images">
                        <Images source= {fraud} alt = "commerce register" className="fraud_image_main"/>
                    </div>
                </div>
            </div>
        </>
    )
}