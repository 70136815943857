import { FC } from "react";
import { Images } from "../../../utils/image";
import moneyRecieve from "../../../../assets/receive-money.png";
import { ButtonDownloadAndroid, ButtonDownloadAPPStore } from "../../../interactions/button";
import "./paymentRecieved.css"

export const PaymentRecievedForUsers: FC = () =>{
    return(
        <>
        <div className="recieve_payment_container">
            <div className="recieve_text_btn_container">
                <div className="recieve_text">
                    <h2 className="payment_h2">Receive payment for goods and services across Africa</h2>
                    <p className="recieve_paragraph">
                        Receiving money via SabiPay payment links is a simple and convenient way to accept payments from customers or clients. 
                        Payment links enable you to share a link with your customers, which they can use to make payments directly 
                        to you. Payment links can be shared via email, text message, social media, or other digital platforms.
                        It's a fast and easy way to accept payments without requiring a physical point of sale. It also offers flexibility and convenience 
                        for customers, as they can make payments from anywhere and at any time. Payment links can also be useful for businesses 
                        that operate online or have remote customers.                             
                    </p>
                </div>
                <div className="download_btn_user">
                    <ButtonDownloadAPPStore Name="Download App" className="app_btn_download" />
                    <ButtonDownloadAndroid Name="Download App" className="app_btn_download" />
                </div>
            </div>
            <Images source={moneyRecieve} alt = "money recieved photo" className="money_recievd_image" />
        </div>
        </>
    )
}