import {FC} from "react";
import { Images } from "../../../utils/image";
import obinna from ".././../../../assets/obinna.jpeg";
import ceo from ".././../../../assets/luckyy.jpeg";
import "./ourteam.css";
import { NewsLetter } from "../newsLetters";

export const OurExcellentTeam: FC = () => {
    return(
        <>
            <div className="meet_our_team">
                <h2 className="meet_our_team_h">Meet our excellent team</h2>
                <p className="meet_our_team_p">
                    We're a diverse, close-knit team on an adventure to build something enduring, while learning something new, every day. 
                </p>
                <div className="our_team_images">
                    <div className="ceo_photo">
                        <Images source = {ceo} alt = "Dima Jin" className="luckyy_image"/>
                        <h4 className="ceo_name">Lucky Uwakwe</h4>
                        <p className="position">CEO Sabii</p>
                    </div>
                    <div className="ceo_photo">
                        <Images source = {obinna} alt = "obinna" className="obinna_image"/>
                        <h4 className="ceo_name obi">Obinna Obidike</h4>
                        <p className="position obi1">COO</p> 
                    </div>
                </div>
            </div>
            <NewsLetter/>
        </>
    )
}