import { FC } from "react";
import { Images } from "../../../utils/image";
import sendMoneImage from "../../../../assets/send-money.png";
import "./sendMoney.css"
import { Thick } from "../../../utils/svg";

export const SenMoneyWithSabiPay: FC =() =>{
    return(
        <>
            <div className="send_money_app_section">
                <div className="send_app_section_content">
                    <Images source={sendMoneImage} className="phone_app_img" />
                    <div className="send_easy_text">
                        <h2 className="send_easy_text_h2">
                            Send money with the SabiPay App Cross-border
                        </h2>
                        <p className="send_easy_text_p">
                            Send on-the-go with our mobile app! With just a few taps, you 
                            can easily transfer funds to friends and family within and outside your country
                        </p>
                        <div className="ease_offers_list">
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list">Send to local bank</div>
                            </div>
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list">Send to SabiPay customer</div>
                            </div>
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list">Send to foreigner</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}