import {FC} from "react";
import { Images } from "../../utils/image";
import "./home.css"
import { Image } from '../../../interface/interface';
import sub_title from "../../../assets/sub_title.png";
import checkout from "../../../assets/checkout.png";

export const Home: FC<Image> =() =>{

    return(
        <>
        <main className="main">
            <section className="customer_payment_image">
                <div className="customer_payment">
                    <span className="pay_with_ease">PAY WITH EASE <Images source = {sub_title} alt="start"/></span>
                    <h2 className="payment_header" >Collect payments from your customers across Africa</h2>
                    <p className="main_paragraph">Let us handle the payment processing so you can focus on growing your business</p>
                    <a href = "https://dashboard.sabipays.com" >
                        <button className="get_started_btn">Get Started</button>
                    </a>
                </div>
                <Images source={checkout} alt = "checkout image" className="checkout_image" />
            </section>
        </main>
        </>
    )
}