import {FC} from "react";
import { Images } from "../../utils/image";
import "./button.css";
import sub_title from "../../../assets/sub_title.png"
import { Apple } from "../../utils/svg";
import android from "../../../assets/android.png";


interface buttonProps{
    className: string;
    width?: string;
    height?: string;
    Name: string;
}

export const Button: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <a href = "https://dashboard.sabipays.com" className = "get_link link" ><span className="m_btn" >{buttonProps.Name}</span></a>
        </>
    )
}
 

// button with start icon in it 
export const ButtonStar: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <span  className="spend_width_ease">{buttonProps.Name} <Images source = {sub_title} alt="start"/></span>
        </>
    )
}


// BUTTON WITH THE APPLE IOS ICON INSIDE IT 
export const ButtonAPPStore: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <span className="app_btn" ><Apple fill="#ff" className="apple_ico" width="22" height="22" />{buttonProps.Name}</span>
        </>
    )
}

// BUTTON WITH THE ANDROID ICON INSIDE IT 
export const ButtonAndroid: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <span className="app_btn" ><Images className="images_android" source = {android} alt = "android" />{buttonProps.Name}</span>
        </>
    )
}

export const NormalBtn:FC<buttonProps> = (buttonProps) =>{
    return(
        <>
           <span className="normal_btn" >{buttonProps.Name}</span> 
        </>
    )
}

// DOWNLOAD BUTTONS FOR BOTH IOS AND ANDROID 
export const ButtonDownloadAndroid: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <span className="download_btn" ><Images className="download_images_android" source = {android} alt = "android" />{buttonProps.Name}</span>
        </>
    )
}
export const ButtonDownloadAPPStore: FC<buttonProps> = (buttonProps) =>{
    return(
        <>
            <span className="download_btn" ><Apple fill="#0c4124" className="download_app_icon" width="22" height="22" />{buttonProps.Name}</span>
        </>
    )
}

// get started button on the eccomerce home section 
export const GetStartedButton:FC = () => {
    return(
        <>
            <a href = "https://dashboard.sabipays.com" className = "link" ><button className="sell_get_started_btn">Get Started</button></a>
        </>
    )
}

export const GetStartedButtonFintech:FC = () => {
    return(
        <>
            <a href = "https://dashboard.sabipays.com" className = "link" ><button className="fintech_get_started_btn" >Get Started</button></a>
        </>
    )
}