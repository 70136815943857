import {FC} from "react";
// ASSETS
import easyImage from "../../../assets/ease.svg";
import transactionImage from "../../../assets/transaction.svg";
import securityImage from "../../../assets/security.svg";
import phoneApp from "../../../assets/phone-app.png";
// COMPONENTS
import { Images } from "../../utils/image";
// STYLE
import "./sendmoney.css";
import { Thick } from "../../utils/svg";

export const SendMoney: FC = () =>{
    return(
        <>
            <div className="send_container">
                <div className="send_content">
                    <div className="send_paragraph_header">
                        <h2 className="send_header">
                            Send money cross-border with ease
                        </h2>
                        <p className="send_paragraph">
                            Secure, reliable, and hassle-free payments are just a click away! Our payment API service makes 
                            it easy for businesses to process transactions quickly and efficiently,with a range of secure payment options 
                        </p>
                    </div>
                    <div className="send_boxes">
                        <div className="easy_to_use_box">
                            <div className="easy_to_use_box_content">
                                <div className="img_div">
                                    <Images source={easyImage} alt = "ease image" className="easy_image"/>
                                </div>
                                <h5 className="ease_to_use_h">
                                    Easy to use
                                </h5>
                                <p className="easy_to_use_paragraph">
                                    APIs are designed with simplicity in mind allowing you to quickly integrate our
                                    services into your application with minimal coding. 
                                </p>
                            </div>
                        </div>
                        <div className="transaction_box">
                            <div className="transaction_box_content">
                                <div className="transaction_img_div">
                                    <Images source={transactionImage} alt = "transaction image" className="transaction_image"/>
                                </div>
                                <h5 className="ease_to_use_h">
                                    Transaction speed
                                </h5>
                                <p className="easy_to_use_paragraph">
                                    Experience lightning-fast transactions with SabiPay! Our high-speed systems enable quick and efficient transactions
                                    providing a seamless user experience.    
                                </p>
                            </div>
                        </div>
                        <div className="security_box">
                            <div className="transaction_box_content">
                                <div className="security_img_div">
                                    <Images source={securityImage} alt = "ease image" />
                                </div>
                                <h5 className="ease_to_use_h">
                                    Security
                                </h5>
                                <p className="easy_to_use_paragraph">
                                    Our state-of-the-art security measures and encryption techniques ensure that your API is secure and protected from potential attacks. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="send_money_app_section">
                    <div className="send_app_section_content">
                        <Images source={phoneApp} alt="phone app image" className="phone_app_img"/>
                        <div className="send_easy_text">
                            <h2 className="send_easy_text_h2">
                                Send money with the SabiPay App Cross-border
                            </h2>
                            <p className="send_easy_text_p">
                                Send money across Africa with ease using our mobile app! Our app makes it easy for you to securely and quickly send money to friends and
                                family across the continent. Say goodbye to expensive transfer fees and long wait times, and hello to hassle-free transfers with just a 
                                few taps on your phone!
                            </p>
                            <div className="ease_offers_list">
                                <div className="mark_text">
                                    <div className="thick_container"><Thick/></div>
                                    <div className="ease_list">  Send in one currency, receive in the others</div>
                                </div>
                                <div className="mark_text">
                                    <div className="thick_container"><Thick/></div>
                                    <div className="ease_list"> Pay for goods with ease</div>
                                </div>
                                <div className="mark_text">
                                    <div className="thick_container"><Thick/></div>
                                    <div className="ease_list"> Send at incredible speed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}