import { FC } from "react";
import "./selltoafricans.css";
import spark from "../../../../assets/1.png"
import lines from "../../../../assets/3line.png";
import { Images } from "../../../utils/image";
import { GetStartedButton } from "../../../interactions/button";

export const SellToAfricans: FC = () => {
    return(
        <>
            <div className="sell_div">
                <div className="sell_content">
                    <div className="sell_t">
                        <div className="sell_top_button_div">
                            <Images source={lines} alt = "3 lines" className="lines" />
                            <div className="button_div">
                                <span className="sabi_ecommerce"> <Images source={spark} alt = "spark" /> Sabi for Ecommerce</span>
                            </div>
                        </div>
                        <h1 className="sell_h1">
                            Sell to Africans <span className="sell_span" >with</span> ease
                        </h1>
                        <p className="sell_p">
                            SabiPay is tailored to African markets and can provide several benefits to businesses looking to sell to African consumers. 
                            It can help to simplify the payment process by offering a range of payment options that are popular in African countries.
                            These can include mobile money, bank transfers, and card payments.
                        </p>
                        <div className="sell_btn">
                            <GetStartedButton/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}