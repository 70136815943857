import {FC} from "react";
import { ArrowRight, InfoBox, SingleSquareStarIcon, StarBoxIcon } from "../../utils/svg";
import infoBox2 from "../../../assets/box2.png";
import starBox from "../../../assets/starbox.png";
import square from "../../../assets/square.png";
import { Images } from "../../utils/image";
import "./paymentBox.css"

export const PaymentProcessBox: FC = () => {
    return(
        <>
            <div className="payment_boxes_div">
                <div className="box_div_content">
                    <div className="box">
                        <div className="info_box1"><InfoBox /></div>
                        <Images source={infoBox2} alt = "box" className="box_img" />
                    </div>
                    <div className="texts">
                        <h5 className="info_box_header">Create a payment request</h5>
                        <p className="info_box_p"> This will typically involve making an API call to our endpoints with the necessary parameters.</p>
                    </div>
                    <div className="get_started_customer-btn_container">
                        <a href = "https://dashboard.sabipays.com" className = "link">
                            <span className="get_started_customer_btn_content">
                                Get Started <ArrowRight className="arrow_right" width="22px" height="22px" fill="#050505;"/>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="box_div_content_process">
                    <div className="box">
                        <div className="info_box3">
                            <StarBoxIcon />
                        </div>
                        <Images source={starBox} alt = "box" className="box_img" />
                    </div>
                    <div className="texts">
                        <h5 className="info_box_header">Process the payment</h5>
                        <p className="info_box_p">Once the payment request has been created, you can use the API to process the payment.</p>
                    </div>
                    <div className="get_started_customer-btn_container">
                        <a href = "https://dashboard.sabipays.com" className = "link">
                            <span className="get_started_customer_btn_content">
                                Get Started <ArrowRight className="arrow_right" width="22px" height="22px" fill="#050505;"/>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="box_div_content handle">
                    <div className="box">
                        <div className="info_box1">
                            <SingleSquareStarIcon />
                        </div>
                        <Images source={square} alt = "box" className="box_img" />
                    </div>
                    <div className="texts">
                        <h5 className="info_box_header">Create a payment request</h5>
                        <p className="info_box_p"> This will typically involve making an API call to our endpoints with the necessary parameters.</p>
                    </div>
                    <div className="get_started_customer-btn_container">
                        <a href = "https://dashboard.sabipays.com" className = "link">
                            <span className="get_started_customer_btn_content">
                                Get Started <ArrowRight className="arrow_right" width="22px" height="22px" fill="#050505;"/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}