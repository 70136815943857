import Hamburger from 'hamburger-react';
import React, {FC, useState} from 'react';
// INTERFACE 
import { Image } from '../../../interface/interface';
// COMPONENTS 
import { Images } from '../../utils/image';
import { ChevronDown } from '../../utils/svg';
// STYLES 
import "./header.css";
// ROUTER
import {NavLink, Outlet} from "react-router-dom";
// chackra
import { Button } from '@chakra-ui/react';
import { aboutLink, brandLink, ecommerceLink, fintechlink, forUsersLink, home } from '../../../routes';
import { Footer } from '../footer';




const Header: FC <Image> = ({source, alt}) => {

    const [toggleNav, setToggleNav] = useState<boolean>(false); 
    
  return (
    <>
        <header className='header' id='header'>
            <nav className='navbar' >
                <div className="container_fluid">
                    <Images className='img' source = {source} alt={alt} />
                    <div className="hamburger">
                        <Hamburger size={20} toggled={toggleNav} toggle={setToggleNav} />
                    </div>
                    <div className="_nav_list">
                        <ul>
                            <li>
                            <NavLink to={home} className='link'>
                                Home 
                            </NavLink>
                            </li>
                            <div className="drop_why">
                                <li  className='link drop'>
                                    {/* <NavLink to={home} className='link drop'> */}
                                        Why SabiPay 
                                        <ChevronDown/>
                                    {/* </NavLink> */}
                                </li>
                                <div className="drop_why_content">
                                    <section className="drop_section">
                                        <ul className="business">
                                            <h2 className="header_drop">Your Growth Stage</h2>
                                            <NavLink to={brandLink} className = "drop_list_link" >
                                                <li className='drop_list'>
                                                    For Global Brands
                                                </li>
                                            </NavLink>
                                            <NavLink to={forUsersLink} className = "drop_list_link" >
                                                <li className='drop_list'>For Users</li>
                                            </NavLink>
                                        </ul>
                                        <ul className="business">
                                            <h2 className="header_drop">Business Types</h2>
                                            <NavLink to={ecommerceLink} className = "drop_list_link">
                                                <li className='drop_list'>For E-commerce</li>
                                            </NavLink>
                                            <NavLink to={fintechlink} className = "drop_list_link" >
                                                <li className='drop_list'>For Fintechs</li>
                                            </NavLink>
                                        </ul>
                                        <ul className="business">
                                            <h2 className="header_drop">Discover</h2>
                                            <li className='drop_list'>Why Choose us</li>
                                            <li className='drop_list'>Testimonies</li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div className="drop_learn">
                                <li className='link drop'>
                                    {/* <NavLink to="/" className='link drop'> */}
                                        Learn 
                                        <ChevronDown/>
                                    {/* </NavLink> */}
                                </li>
                                <section className="drop_learn_content">
                                    <ul className="learn_list_container">
                                        <NavLink to={aboutLink} className='link mobile_link'>
                                            <li className="learn_list">
                                                About Us
                                            </li>
                                        </NavLink>
                                        <li className="learn_list">
                                            Careers
                                        </li>
                                        <li className="learn_list">
                                            Blog
                                        </li>
                                    </ul>
                                </section>
                            </div>
                            <li className='link'>
                                <a 
                                    href="https://documenter.getpostman.com/view/21239609/2sA3kVn2Qt" 
                                    target="_blank" 
                                    className='link'
                                    rel="noreferrer"
                                >
                                    Developers 
                                </a>
                            </li>
                        </ul> 
                    </div>
                    <div className="authentication_link">
                        <ul>
                            <a className='link authlink' href="https://dashboard.sabipays.com/login">
                                <Button bg="none">Login</Button>
                            </a>
                            <a className='link authlink' href="https://dashboard.sabipays.com">
                                <Button bg="none">Create Account</Button>
                            </a>
                        </ul>
                        <Button variant="solid"  className="app_btn_header">Download App</Button>
                    </div>
                </div>
                {/* MOBILE NAVIGATION LIST STARTS */}
                {toggleNav &&(
                <div className="mobile_nav_list">
                <ul>
                    <li>
                    <NavLink to="/" className='link mobile_link'>
                        Home 
                    </NavLink>
                    </li>
                    <div className="drop_why_mobile">
                        <li className='sabi' >
                            <NavLink to="/" className='link mobile_link'>
                                Why SabiPay 
                                <ChevronDown/>
                            </NavLink>
                        </li>
                        <div className="drop_why_content_mobile">
                            <section className="drop_section_mobile">
                                <ul className="business">
                                    <h2 className="header_drop_mobile">Your Growth Stage</h2>
                                    <NavLink to={brandLink} className='link mobile_link'>
                                        <li className='drop_list_mobile'>
                                            Global Brands
                                        </li>
                                    </NavLink>
                                    <NavLink to={forUsersLink} className='link mobile_link'>
                                        <li className='drop_list_mobile'>For Users</li>
                                    </NavLink>
                                </ul>
                                <ul className="business">
                                    <h2 className="header_drop_mobile">Business Types</h2>
                                    <NavLink to={ecommerceLink} className='link mobile_link'>
                                        <li className='drop_list_mobile'>For E-commerce</li>
                                    </NavLink>
                                    <NavLink to={fintechlink} className='link mobile_link' >
                                        <li className='drop_list_mobile'>For Fintechs</li>
                                    </NavLink>
                                </ul>
                                <ul className="business">
                                    <h2 className="header_drop_mobile">Discover</h2>
                                    <li className='drop_list_mobile'>Why Choose us</li>
                                    <li className='drop_list_mobile'>Testimonies</li>
                                </ul>
                            </section>
                        </div>
                    </div>
                    <div className="learn-drop_mobile">
                        <li>
                            <NavLink to="/" className='link mobile_link'>
                                Learn 
                                <ChevronDown/>
                            </NavLink>
                        </li>
                        <div className="drop_learn_content_mobile">
                            <section className="drop_learn_section_mobile">
                                <ul className="learn_list_container_mobile">
                                <NavLink to={aboutLink} className='link mobile_link'>
                                    <li className="learn_list_mobile">
                                        About Us
                                    </li> 
                                </NavLink>
                                    <li className="learn_list_mobile">
                                        Careers
                                    </li>
                                    <li className="learn_list_mobile">
                                        Blog
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                     <li>
                        <NavLink to="/" className='link mobile_link'>
                            Developers 
                        </NavLink>
                    </li>
                </ul> 
                </div>
                )}
                {/* MOBILE NAVIGATION LIST ENDS */}
            </nav>
        </header>
        <Outlet/>
        <Footer/>
    </>
  )
};

export default Header;


