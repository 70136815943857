import { FC } from "react";
import { Images } from "../../../utils/image";
import reconciliation from "../../../../assets/reconciliation.png";
import "./reporting.css";


export const ReportingAndReconciliation: FC = () =>{
    return(
        <>
             <div className="reporting_div">
                <div className="reporting_content">
                    <div className="reporting_images">
                        <Images source= {reconciliation} alt = "commerce register" className="commerce_image_reconciliation"/>
                    </div>
                    <div className="reporting_t">
                        <h2 className="reporting_h2" >Reporting & Reconciliation</h2>
                        <p className="reporting_p">
                            Understand your customers' purchase patterns and do easy reconciliations with a robust data Dashboard and easy exports. 
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}