import {FC} from "react"
import { ButtonAndroid, ButtonAPPStore, ButtonStar } from "../../interactions/button"
import { Images } from "../../utils/image"
import userApp from "../../../assets/user-app.png";
import "./forusers.css";

export const ForUsers: FC = () =>{
    return(
        <>
            <main className="user_main_container">
                <div className="users_content">
                    <section className="user_top">
                        <div className="user_send_recieve_money_t">
                            <ButtonStar Name="SPEND WITH EASE" className = "spend_ease_btn"/>
                            <div className="user_t">
                                <h2 className="user_h">
                                    Send money. Receive money. Pay Bills.
                                </h2>
                                <p className="user_p">
                                    Our secure and convenient platform makes managing your finances a breeze. 
                                    Download our app today and start sending and receiving money anytime, anywhere! 
                                </p>
                            </div>
                            <div className="app_btn_div">
                                <ButtonAPPStore Name = "App Store" className="app_store"/>
                                <ButtonAndroid Name = "Google Play" className="google-play"/>
                            </div>
                        </div>
                        <Images source={userApp} alt = "send money" className="user_app_img" />
                    </section>
                </div>
            </main>
        </>
    )
}