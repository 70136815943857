import {FC} from "react";
import { Button } from "@chakra-ui/react";
import { Images } from "../../utils/image";
import logoIcon from "../../../assets/logo-icon.png"
import { EnvelopeIcon, Facebook, Instagram, LinkedIn, LocationIcon, PhoneIcon, Twitter } from "../../utils/svg";
import "./footer.css"

export const Footer:FC =() =>{
    return(
        <>
            <footer>
                <div className="footer_container">
                    <div className="get_started_container">
                        <div className="get_started_content">
                            <div className="get_started_text">
                                <h2 className="get_started_h">Get Started</h2>
                                <p className="get_started_p"> Start Accepting payments from African customers in 30mins</p>
                            </div>
                            <a  href="https://dashboard.sabipays.com">
                                <Button className="footer_create_account_btn" >Create Account</Button>
                            </a>
                        </div>
                    </div>
                    <div className="address_contant_link_container">
                        <div className="main_info_list">
                            <section className="address">
                                <Images source={logoIcon} alt = "logo icon" className="logo_icon" />
                                <ul className="address_list">
                                    <li className="ad"><LocationIcon width = "20px" height="20px" fill = "#fff"/>Church Gate Tower Abuja</li>
                                    <li className="ad"><EnvelopeIcon width="20" height="20" fill = "#fff" />hello@sabipays.com</li>
                                    <li className="ad"><PhoneIcon width="20" height="20" fill = "#fff" />+2347044449038, +2347068189427</li>
                                </ul>
                            </section>
                            <section className="learn">
                                <h5 className="learn_h">Learn</h5>
                                <ul className="learn-list">
                                    <li className="us">About Us</li>
                                    <li className="us">Careers</li>
                                    <li className="us">Team</li>
                                    <li className="us">Blog</li>
                                </ul>
                            </section>
                            <section className="why_sabipay-footer">
                                <h5 className="whysabipay_footer_h">Why Sabipay</h5>
                                <ul className="why_sabipay_footer_list">
                                    <li className="why_sabi_footer_li">For Global Brands</li>
                                    <li className="why_sabi_footer_li">For Users</li>
                                    <li className="why_sabi_footer_li">For Ecommerce</li>
                                </ul>
                            </section>
                            <section className="why_sabipay-footer">
                                <h5 className="whysabipay_footer_h">Support</h5>
                                <ul className="why_sabipay_footer_list">
                                    <li className="why_sabi_footer_li">Support</li>
                                    <li className="why_sabi_footer_li">FAQ</li>
                                    <li className="why_sabi_footer_li">Api Documentation</li>
                                    <li className="why_sabi_footer_li">Privacy</li>
                                </ul>
                            </section>
                        </div>
                        <div className="line"></div>
                        <section className="copy_right">
                            <p className="copy_right_p" >© 2023 SabiPay. All Rights Reserved</p>
                            <div className="icons">
                                <div className="icon"><Instagram width="20px" height="20px" fill="#a9a8ad" /></div>
                                <div className="icon"><Facebook width="20px" height="20px" fill="#a9a8ad" /></div>
                                <div className="icon"><LinkedIn width="20px" height="20px" fill="#a9a8ad" /></div>
                                <div className="icon"><Twitter width="20px" height="20px" fill="#a9a8ad" /></div>
                            </div>
                        </section>
                    </div>
                </div>
            </footer>
        </>
    )
}