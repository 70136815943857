import { FC } from "react"
import { GlobalBrands } from "../../components/layouts/globalBrands"
import { PaymentProcessBox } from "../../components/layouts/paymentProcessBox"

export const GlobalBrandView: FC = () =>{
    return(
        <>
            <GlobalBrands/>
            <PaymentProcessBox/>
        </>
    )
}