import { FC } from "react";
import { Thick } from "../../../utils/svg";
import virtualCard from "../../../../assets/virtual-cards.png"
import { Images } from "../../../utils/image";
import "./govisual.css"
import { NormalBtn } from "../../../interactions/button";


export const GoVisual: FC = () =>{
    return(
        <>
            <div className="send_money_app_section">
                <div className="send_app_section_content">
                    <Images source={virtualCard} className="phone_app_img" />
                    <div className="send_easy_text">
                        <h2 className="send_easy_text_h2 h2_user_virtual">
                            Go Virtual: Safe and Convenient Online Payments with  SabiCards
                        </h2>
                        <p className="send_easy_text_p">
                            Sabicards are digital payment cards that can be used to pay for goods or services online. 
                            These cards are not physical and do not have a physical presence.                            
                        </p>
                        <p className="send_easy_text_p">
                            One of the main advantages of Sabicards is that they offer increased security for online transactions. Since the card number is not physically present, 
                            it cannot be stolen or used fraudulently in the same way as a physical card.                        
                        </p>
                        <div className="ease_offers_list">
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list"> Fund card from SabiWallet</div>
                            </div>
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list"> Pay for goods globally</div>
                            </div>
                            <div className="mark_text">
                                <div className="thick_container"><Thick/></div>
                                <div className="ease_list"> 24/7 Support</div>
                            </div>
                            <div className="comming_soon_btn_div">
                                <NormalBtn className="coming_soon_btn" Name="Coming soon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}