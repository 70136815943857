import React, { FC } from 'react';
import './App.css';
import Header from './components/layouts/headerr';
import { HomePage } from './views/homePage';
import logo from "./assets/sab-text.png"
// import sub_title from "./assets/sub_title.png";
// ROUTER
import {Route, Routes} from "react-router-dom";
// LINK PATH
import { about, brand, ecommerce, fintech, forUsers, home } from './routes';
import { AboutPageView } from './views/aboutPage';
import { GlobalBrandView } from './views/globalBrand';
import { ForUsersView } from './views/forUsers';
import { SellToAfricansView } from './views/ecommerce';
import { FintechView } from './views/fintech';





 const App: FC =() => {

  return (
    <>
      
      <Routes>
        <Route path={home} element = {<Header source = {logo} alt ="logo" />} >
          <Route index element = {<HomePage/>} />
          <Route path={about} element = {<AboutPageView/>} />
          <Route path={brand} element = {<GlobalBrandView/>} />
          <Route path= {forUsers} element = {<ForUsersView/>} />
          <Route path={ecommerce} element = {<SellToAfricansView/>} />
          <Route path={fintech} element = {<FintechView/>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
