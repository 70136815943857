import {FC} from "react";
import { Images } from "../../utils/image";
import worldImage from "../../../assets/world.png";
import customer from "../../../assets/customer.png";
import checkout from "../../../assets/checkout.png";
// STYLE
import "./globalBrands.css";
import { ArrowRight } from "../../utils/svg";


export const GlobalBrands : FC = () => {
    return(
        <>
            <div className="global_brands_container">
                <div className="brand_content">
                    <div className="brand_top">
                        <div className="brand-top_text">
                            <h1 className="brand_top_h1">Complete payment solution for <br className="br" /><span className="global_brand_span" >Global brands</span></h1>
                            <p className="brand_top_p">We make customer service better. We build software to meet customer needs</p>
                        </div>
                        <Images source = {worldImage} alt = "world" className="world_img"/>
                    </div>
                    <div className="customer_section">
                        <div className="image">
                            <Images source={customer} alt= "customer" className="customer_img"/>
                        </div>
                        <div className="customer_t">
                            <div className="cutomer_h_p">
                                <h2 className="customer_header">Unleash the Power of <span className="custoer_"> Seamless Customer Experience.</span></h2>
                                <p className="customer_p"> 
                                  We have a robust suite of well-documented payments APIs and deep knowledge of the African payments
                                 landscape, making us ideal partners for international technology companies looking to accept payments in Africa. 
                                </p>
                            </div>
                            <div className="get_started_customer-btn_container">
                                <a href = "https://dashboard.sabipays.com" className="link">
                                    <span className="get_started_customer_btn_content">
                                        Get Started <ArrowRight className="arrow_right" width="22px" height="22px" fill="#050505;"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CUSTOMER PAYMENT CHECKOUT START */}
                <div className="checkout_div_main">
                    <div className="customer_tt">
                        <div className="cutomer_h_p">
                            <h2 className="customer_header checkout_header">Custom  <span className="checkout_">payment </span> checkout</h2>
                            <p className="customer_p checkout_p"> 
                                If you're looking to create a custom payment experience using an API, 
                                SabiPay fully gives that to ensure your customers do not leave your website and still your branding in the checkout process 
                            </p>
                        </div>
                        <div className="get_started_customer-btn_container">
                            <Images source={checkout} alt = "checkout image" className="checkout_image" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}