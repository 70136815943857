
import { FC } from "react";
import { CollectPaymentWithEase } from "../../components/layouts/ecommerce/collectPaymentEase";
import { IntergratePaymentApi } from "../../components/layouts/ecommerce/paymentApiIntegrate";
import { Register } from "../../components/layouts/ecommerce/register";
import { SellToAfricans } from "../../components/layouts/ecommerce/sellToAfricans";


export const SellToAfricansView: FC = () => {
    return(
        <>
            <SellToAfricans/>
            <Register/>
            <IntergratePaymentApi/>
            <CollectPaymentWithEase/>
        </>
    )
}