import { FC } from "react";
import { Images } from "../../../utils/image";
import wallet from "../../../../assets/wallet.png";
import "./wallet.css";


export const WalletManagementSystem: FC = () =>{
    return(
        <>
            <div className="wallet_div">
                <div className="wallet_content">
                    <div className="wallet_images">
                        <Images source= {wallet} alt = "commerce register" className="wallet_management_img"/>
                    </div>
                    <div className="wallet_management_t">
                        <h2 className="wallet_management_h2" >Wallet Management System</h2>
                        <p className="wallet_management_p">
                            Build solutions that enable your customers store, manage, and use their wallet balances for purchases across Africa 
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}