import {FC} from "react";
import { Button } from "../../interactions/button";
import { Images } from "../../utils/image";
// ASSETS
import money_recieved from "../../../assets/receive-money.png";
import dashboardImage from "../../../assets/dashboard_img.png";
import ceo from "../../../assets/n.jpg";
// styles
import "./payment.css"

export const PaymentRecieved: FC = () =>{
    return(
        <>
            <div className="recieve_payment_container">
                <div className="recieve_text_btn_container">
                    <div className="recieve_text">
                        <h2 className="payment_h2">Receive payment for goods and services across Africa</h2>
                        <p className="recieve_paragraph">
                            Receiving money via SabiPay payment links is a simple and convenient way to accept payments from customers or clients. 
                            Payment links enable you to share a link with your customers, which they can use to make payments directly 
                            to you. Payment links can be shared via email, text message, social media, or other digital platforms.
                            It's a fast and easy way to accept payments without requiring a physical point of sale. It also offers flexibility and convenience 
                            for customers, as they can make payments from anywhere and at any time. Payment links can also be useful for businesses 
                            that operate online or have remote customers.                             
                        </p>
                    </div>
                    <div className="get_btn">
                            <Button className="_btn"  Name="Get Started"/>
                    </div>
                </div>
                <Images source={money_recieved} alt = "money recieved photo" className="money_recievd_image" />
            </div>
            {/* ACCEPT PAYMENT START*/}
            <section className="accept_payment_div">
                <div className="accept_h_p">
                    <h2 className="accept_payment_h">
                        Accept payments across Africa with our well-documented APIs 
                    </h2>
                    <p className="accept_payment_p">
                        APIs are designed with simplicity in mind allowing you to quickly integrate our services into your application with minimal coding. 
                    </p>
                </div>
                <div className="accpet_payment_img">
                    <Images source={dashboardImage} alt="dashboard" className="money_recievd_image1"/>
                </div>
            </section>
            {/* ACCEPT PAYMENT END*/}
            {/* CUSTOMERS */}
            <section className="our_happy_customers">
                <div className="our_customer_content">
                    <h2 className="our_customer_h2">Our happy customer stories</h2>
                    <p className="our_customer_p">
                        <span id="quote" >“</span>
                        SabiPay has been a great partner for us when it comes to expanding our acquiring capabilities
                        in Africa, leveling up the region's acquiring capabilities. Their experience in the market has been invaluable, helping us scale 
                        <span id="quote">”</span>
                    </p>
                </div>
                <div className="ceo_name_image">
                    <Images source={ceo} alt="Lucky Uwakwe" className="ceo_img"/>
                    <div className="ceo_info_text">
                        <h5 className="ceo_name">Dima Jin</h5>
                        <p className="sabi_ceo">CEO Enjoy Global</p>
                    </div>
                </div>
            </section>
        </>
    )
}