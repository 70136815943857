import {FC} from "react";
// import newsletter from "../../../../assets/subscrib.png";
import "./newsletter.css";
import { Button } from "@chakra-ui/react";

export const NewsLetter: FC =() =>{
    return(
        <>
        <div className="newsletter">
            <div className="newsletter_image">
                <div className="content">
                    <h5 className="newsletter_h5">Get in touch with us</h5>
                    <h2 className="newsletter_h2">Subscribe to our Newsletter</h2>
                    <div className="news_letter_form">
                        <div className="input_div">
                            <input type="text" placeholder="Full name" className="fullname_input input"/>
                            <input type="email" placeholder="Email Address" className="email_input input"/>
                        </div>
                        <Button type="submit" className="subscribe_btn">Subscribe <span className="subscribe_span" ></span></Button>
                    </div>
                    <p className="span_message">We won't span you. keep you updated</p>
                </div>
            </div>
        </div>
        </>
    )
}