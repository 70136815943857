// major routes 
export const main = "/main";
export const home = "/";
// nested routes 
export const about = "about";
export const brand = "global_brands";
export const forUsers = "forusers";
export const ecommerce = "ecommerce";
export const fintech = "fintech";

// link
export const aboutLink = "/about"
export const brandLink = "/global_brands"
export const forUsersLink = "/forusers"
export const ecommerceLink = "/ecommerce"
export const fintechlink = "/fintech"