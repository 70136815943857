import {FC} from "react"
import { Home } from "../../components/layouts/home"
import { PaymentRecieved } from "../../components/layouts/paymentRecieved"
import { SendMoney } from "../../components/layouts/sendMoney"

export const HomePage: FC =() =>{
    return(
        <>
            <Home/>
            <SendMoney/>
            <PaymentRecieved/>
        </>
    )
}