import{ FC} from "react";
import { Images } from "../../../utils/image";
import "./aboutus.css";
import image1 from "../../../../assets/about-image.jpeg";
import image2 from "../../../../assets/about-image2.jpeg";

export const AboutUs:FC = () =>{
    return(
        <>
            <div className="about_container">
                <div className="about_content">
                    <div className="top_info">
                        <span className="about_btn" >About Us</span>
                        <h1 className="about_top_header">
                            Connecting African consumers to global markets 
                        </h1>
                    </div>
                    <div className="about_us_top_image">
                        <Images source={image1} alt = "about" className="about_image1 img1" />
                        <Images source={image2} alt = "about " className="about_image1 img2"/>
                    </div>
                    <div className="help_african_m">
                        <h2 className="help_african_h2">
                            Helping African consumers pay for global goods
                        </h2>
                        <p className="help_african">
                            With the increasing trend of global e-commerce, it's becoming more commonfor consumers to purchase goods from merchants located in different
                            countries. However, making international payments can be a complex and expensive process, especially for consumers who are unfamiliar with
                            foreign exchange rates and transaction fees. SabiPay full solves this gridlock   
                        </p>
                    </div>
                    <div className="about_money">
                        <section className="one">
                            <span className="ones" >01</span>
                            <h4 className="send_money">Send Money</h4>
                            <p className="send_money_p">
                                Send on-the-go with our mobile app! With just a few taps, you can easily transfer funds to
                                friends and family within and outside your country 
                            </p>
                        </section>
                        <section className="two">
                            <span className="ones" >02</span>
                            <h4 className="receive_money">Receive Money</h4>
                            <p className="receive_money_p">
                                Receiving money via SabiPay payment links is a simple and convenient way to accept payments from customers or clients. 
                                Payment links enable you to share a link with your customers.
                            </p>
                        </section>
                        <section className="collect_payment">
                        <span className="ones" >03</span>
                        <h4 className="collect_payment_h">Collect Payments</h4>
                            <h4 className="collect_payment_p">
                                We have a robust suite of well-documented payments APIs and deep knowledge of the African payments landscape,
                                making us ideal partners for international technology companies looking to accept payments in Africa.
                            </h4>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}