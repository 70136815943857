import { FC } from "react";
import { GetStartedButton } from "../../../interactions/button";
import { Images } from "../../../utils/image";
import commerceImg from "../../../../assets/commerce-api.jpeg";
import "./paymentapi.css";

export const IntergratePaymentApi: FC = () =>{
    return(
        <>
            <div className="payment_div">
                <div className="payment_content">
                    <div className="payment_images">
                        {/* <Images source={star} alt = "star line" className="line_star" /> */}
                        <Images source= {commerceImg} alt = "commerce register" className="payment_image_register"/>
                    </div>
                    <div className="payment_t">
                        <h1 className="payment_h1">
                            Integrate the Payments <span className="payment_span" >Api</span>
                        </h1>
                        <p className="payment_p">
                            Once activated, get your unique API keys and started interacting with our APIs to collect payments  
                        </p>
                        <div className="payment_btn">
                            <GetStartedButton/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}