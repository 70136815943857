import { FC } from "react";
import { CustomSolution } from "../../components/layouts/fintech/customSolution";
import { OurEquipApi } from "../../components/layouts/fintech/ourApiEquip";
import { FraudPrevention } from "../../components/layouts/fintech/prevention";
import { ReportingAndReconciliation } from "../../components/layouts/fintech/reporting";
import { WalletManagementSystem } from "../../components/layouts/fintech/walletManagement";


export const FintechView: FC = () =>{
    return(
        <>
            <CustomSolution/>
            <ReportingAndReconciliation/>
            <FraudPrevention/>
            <WalletManagementSystem/>
            <OurEquipApi/>
        </>
    )
}