import { FC } from "react";
import { GetStartedButtonFintech } from "../../../interactions/button";
import { Images } from "../../../utils/image";
// ASSETS
import customImage from "../../../../assets/dmh1.png";
import node from "../../../../assets/node.png";
import python from "../../../../assets/python.png";
import react from "../../../../assets/react.png";
import code from "../../../../assets/code.png";
import youtube from "../../../../assets/Youtube.png";
import php from "../../../../assets/php.png";
// CSS
import "./custom.css";


export const CustomSolution: FC = () =>{
    return(
        <>
            <div className="custom_container">
                <div className="custom_content">
                    <div className="custom_t">
                        <h2 className="custom_h2">
                            Build custom payment solutions that connect African consumers to Global businesses
                        </h2>
                        <p className="custom_p">
                            Everything you need to build disruptive products in savings, lending, insurance, and more 
                        </p>
                        <div className="custom_btn">
                            <GetStartedButtonFintech />
                        </div>
                    </div>
                    <div className="custom_image_container">
                        <div className="custom_floating_image">
                            <Images source={php} alt="php" className="floating_icon php_icon" />
                            <Images source={node} alt="node" className="floating_icon node_icon"/>
                            <Images source={python} alt="python" className="floating_icon python_icon" />
                            <Images source={react} alt="react" className="floating_icon react_icon" />
                            <Images source={code} alt="code" className="floating_icon code_icon"/>
                            <Images source={youtube} alt = "youtube" className="floating_icon youtube_icon"/>
                        </div>
                        <div className="custom_bold_image">
                            <Images source={customImage} alt="custom" className="custom_img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}