import { FC } from "react";
import { Images } from "../../../utils/image";
// ASSETS
import info1 from "../../../../assets/info-icon1.png";
import info2 from "../../../../assets/dm_info2.png";
import info3 from "../../../../assets/dm_info3.png";
import info4 from "../../../../assets/dm_info4.png";
import "./ourApi.css"


export const OurEquipApi: FC = () =>{
    return(
        <>
            <div className="equip_api_div">
                <div className="api_content">
                    <h2 className="equip_api_h2">
                        Our APIs Equip you to build innovative solutions
                    </h2>
                    <section className="equip_api_box_div">
                        <div className="api_box">
                            <Images source = {info1} alt = "info icon" className="info1" />                    
                            <div className="api_t">
                                <h5 className = "api_h5" > Charge Authoriztions API </h5>
                                <p className = "api_p"> Debit a SabiPay customer, and get a token to use for future debits </p>
                            </div>
                        </div>
                        <div className="api_box">
                            <Images source = {info2} alt = "info icon" className="info1" />                    
                            <div className="api_t">
                                <h5 className = "api_h5" >Account Number Generation API</h5>
                                <p className = "api_p">Generate account numbers of financial institutions across Africa</p>
                            </div>
                        </div>
                        <div className="api_box">
                            <Images source = {info3} alt = "info icon" className="info1" />                    
                            <div className="api_t">
                                <h5 className = "api_h5" >Transactions API </h5>
                                <p className = "api_p">Get paid by customers across different countries</p>     
                            </div>
                        </div>
                        <div className="api_box">
                            <Images source = {info4} alt = "info icon" className="info1" />                    
                            <div className="api_t">
                                <h5 className = "api_h5" >Bank Transfer</h5>
                                <p className = "api_p">Send money across banks in Africa using the Sabi API</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}