import { FC } from "react";
import { GetStartedButton } from "../../../interactions/button";
import { Images } from "../../../utils/image";
import collectpayment from "../../../../assets/collect-payment.png"
import "./collectpayment.css";

export const CollectPaymentWithEase:FC = () =>{
    return(
        <>
            <div className="collect_payment_divcontainer">
                <div className="payment_div_content">
                    <div className="payment_images_collect">
                        {/* <Images source={star} alt = "star line" className="line_star" /> */}
                        <Images source= {collectpayment} alt = "commerce register" className="payment_image_collect"/>
                    </div>
                    <div className="payment_collect_ease_t">
                        <h1 className="payment_collect_ease_h1">
                            Collect Payments width <span className="payment_collect_ease_span" >ease</span>
                        </h1>
                        <p className="payment_collect_ease_p">
                            Fast and Easy, you can commence payment collection across Africa in 30mins  
                        </p>
                        <div className="payment_collect_ease_btn">
                            <GetStartedButton/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}